import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

export * from '@weavix/models/src/analytics/analytics';

declare global {
    interface Window {
        analytics: SegmentAnalytics.AnalyticsJS;
    }
}

const source = environment.analyticsSource;
const version = environment.version;
const USE_ANALYTICS_KEY = 'use-analytics';

function trackIt() {
    return document.cookie.indexOf('e2e=true') === -1
         && !!localStorage.getItem(USE_ANALYTICS_KEY);
}

export interface IdentifyTraits {
    email: string;
    name: string;
    accountId: string;
}

export enum StProperty {
    accountId = 'accountId',
    action = 'action',
    channelId = 'channelId',
    channelType = 'channelType',
    component = 'component',
    count = 'count',
    destinationLanguages = 'destinationLanguages',
    dimensions = 'dimensions',
    duration = 'duration',
    endpoint = 'endpoint',
    errorData = 'errorData',
    id = 'id',
    memberCount = 'memberCount',
    method = 'method',
    object = 'object',
    page = 'page',
    query = 'query',
    source = 'source',
    sourceLanguage = 'sourceLanguage',
    type = 'type',
    unit = 'unit',
    version = 'version',
    visible = 'visible',
}

export type StProperties = { [key in StProperty]?: any };


const allowedKeys = new RegExp((Object.keys(StProperty).join('|')), 'i');
function redact(obj: any) {
    if (Array.isArray(obj)) {
        const result = [];
        for (let i = 0; i < obj.length; i++) {
            const redacted = redact(obj[i]);
            result.push(redacted === undefined ? !!obj[i] : redacted);
        }
        return result;
    } else if (obj && typeof obj === 'object') {
        const result = {};
        Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (allowedKeys.test(key)) {
                result[key] = val;
            } else {
                const redacted = redact(val);
                result[key] = redacted === undefined ? !!val : redacted;
            }
        });
        return result;
    }
    return undefined;
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {

    private static readonly USE_ANALYTICS_KEY = 'use-analytics';

    static trackIt() {
        return trackIt();
    }

    static start() {
        localStorage.setItem(this.USE_ANALYTICS_KEY, 'true');
    }

    static stop() {
        localStorage.removeItem(this.USE_ANALYTICS_KEY);
    }

    static identify(userId: string, traits: IdentifyTraits): void {
        if (trackIt()) window.analytics.identify(userId, { ...traits });
    }

    static track(object: string, action: string, component: string, data: StProperties = {}): void {
        try {
            if (trackIt()) {
                const allProps: StProperties = { object, action, version, source, component, ...redact(data) };
                window.analytics.track(`${object} : ${action}`, allProps);
            }
        } catch (e) {
            console.error(e);
        }
    }

    static error(method: string, endpoint: string, component: string, errorData: StProperties = {}): void {
        try {
            if (trackIt()) {
                const allProps: StProperties = { method, endpoint, version, source, component, ...redact(errorData) };
                window.analytics.track(`HTTP ERROR : ${method}`, allProps);
            }
        } catch (e) {
            console.error(e);
        }
    }

    static page(page: string): void {
        try {
            if (trackIt()) {
                const allProps: StProperties = { action: 'Viewed', page, source, version };
                window.analytics.track(`${page} : Viewed`, allProps);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
